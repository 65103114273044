<template>
    <div class="slideContent">
        <div class="pageContent">

            <div class="content">
                <div class="titleBox">
                    <div class="title">
                        <div style="font-size:25px;color:#fff">Just somethings that i like to do</div>
                    </div>

                </div>
                <div style="height:40px"></div>
                <div class="text">
                    Architectural 3D rendering
                </div>
            </div>

            <GalleryStatic box-size-h="100" box-size-w="100" box-quantity="22" z-index="0"/>
            
        </div>
    </div>
</template>

<script>

import Gallery from '@/components/Gallery.vue'
import GalleryStatic from '@/components/GalleryStatic.vue'


export default {
    name:'Portfolio',
    components:{
        Gallery,
        GalleryStatic
    },
    data(){
        return{
        }
    },
    beforeMount(){

    },
    computed:{

    },
    methods:{

    }
}
</script>

<style scoped>

.slideContent{
    /*background: linear-gradient(-45deg, rgba(240,250,250,1) 0%, rgba(255,255,255,.9) 50%, rgba(240,250,250,.8) 100%);
    background:radial-gradient(circle, rgba(123,19,43,1) 0%, rgba(0,0,0,1) 100%);*/

    backdrop-filter: blur(24px) brightness(0.6) saturate(1.8);
    padding: 40px;
    margin: auto;
    text-align:left;
    color:white;
    width: 100%;
    height: 100vh;
}
.pageContent{
    max-width: 1280px;
    position:relative;
    margin:auto;
}
.content{
    position: relative;
    z-index: 1;
}
.titleBox{
    color:white;
}
.text{
    font-size: 20px;
}
.title{
    font-weight: 700;
    text-align:left;
    color:white;
}
</style>