import Vue from 'vue'
import Vuex from 'vuex'

import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  Color,
  FogExp2,
  CylinderBufferGeometry,
  MeshPhongMaterial,
  Mesh,
  DirectionalLight,
  AmbientLight,
  AnimationMixer,
  Vector3,
  Clock
} from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
import { SphereGeometry } from "three/src/geometries/SphereGeometry.js";
import { BoxGeometry } from "three/src/geometries/BoxGeometry.js";
import { TextGeometry } from "three/src/geometries/TextGeometry.js";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FontLoader } from 'three/src/loaders/FontLoader.js';

import {
  AbsoluteOrientationSensor,
  RelativeOrientationSensor
} from '../motion-sensors.js';

let sensor;


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    frutas: [
      {nombre:'Manzana', cantidad:0},
      {nombre:'Pera', cantidad:0},
      {nombre:'Naranja', cantidad:0}
    ],
    param:{},
    width: 0,
    height: 0,
    camera: null,
    controls: null,
    scene: null,
    renderer: null,
    mixer: null,
    clock: null,
    head:null,
    pyramids: []
  },
  getters: {
    CAMERA_POSITION: state => {
      return state.camera ? state.camera.position : null;
    }
  },
  mutations: {
    aumentar(state, index){
      state.frutas[index].cantidad++
    },
    SET_VIEWPORT_SIZE(state, { width, height }) {
      state.width = width;
      state.height = height;
    },
    INITIALIZE_RENDERER(state, el) {
      state.renderer = new WebGLRenderer({ antialias: true });
      state.renderer.setPixelRatio(window.devicePixelRatio);
      state.renderer.setSize(state.width, state.height);
      el.appendChild(state.renderer.domElement);
    },
    INITIALIZE_CAMERA(state) {
      state.camera = new PerspectiveCamera(
        // 1. Field of View (degrees)
        60,
        // 2. Aspect ratio
        state.width / state.height,
        // 3. Near clipping plane
        1,
        // 4. Far clipping plane
        1000
      );
      state.camera.position.z = 4;
    },
    INITIALIZE_CONTROLS(state) {
      state.controls = new TrackballControls(
        state.camera,
        state.renderer.domElement
      );
      state.controls.rotateSpeed = 0;
      state.controls.zoomSpeed = 1.2;
      state.controls.panSpeed = 0.8;
      state.controls.noZoom = true;
      state.controls.noPan = false;
      state.controls.staticMoving = true;
      state.controls.dynamicDampingFactor = 0.3;
      state.controls.keys = [65, 83, 68];
      state.controls.target.set(0, 0, 0);
      
    },
    UPDATE_CONTROLS(state) {
      state.controls.update();
    },
    INITIALIZE_SCENE(state) {
      state.scene = new Scene();
      //state.scene.background = new Color(0xff7f4b);
      state.scene.background = new Color(0x222222);
      state.scene.fog = new FogExp2(0x000000, .05);

      var tope =100;

      var geometry = new BoxGeometry(80,50, 0);
      
      var words = [
        'AngularJS',
        'Angular2+',
        'VueJS',
        'Regex',
        'SEO',
        'NodeJS',
        'JavaScript',
        'Tag Manager',
        'Sass',
        'CSS',
        'HTML5',
        'Bootstrap',
        'jQuery',
        'AJAX',
        'SVG',
        'Canvas',
        'ThreeJS',
        'Material',
        'PHP',
        'MySQL',
        'Flutter',
        'Wordpress',
        'Firebase',
        'Cloudflare'
      ];
      state.textMesh = [];

      var material = new MeshPhongMaterial({
        color: 0x1add1a,
        flatShading: true
      });
      words.forEach((word, key)=>{
        var textLoader = new FontLoader();
        textLoader.load('/montserrat.json',function(font){
            var geo = new TextGeometry( word, {
                font: font,
                size: (Math.random()*2)+1,
                height: 0.01,
                curveSegments: 6,
                bevelEnabled: false,
                bevelThickness: 0.1,
                bevelSize: 0.1,
                bevelSegments: 0.1
            } );
  
            
            state.textMesh[key] = new Mesh(geo, material);
            state.textMesh[key].position.x = (Math.random() * 100) -50;
            state.textMesh[key].position.y = (Math.random() * 20) -10;
            state.textMesh[key].position.z =  (Math.random() * -15) -14;
            state.scene.add(state.textMesh[key]);
        });
      });

      var eyeGeometry = new SphereGeometry(.1,10,10,0);
      var eyeMaterial = new MeshPhongMaterial({
        color: 0xffffff,
        flatShading: true
      });
      state.eyeMesh = new Mesh(eyeGeometry, eyeMaterial);
      state.eyeMesh.position.x=0;
      state.eyeMesh.position.y=1;
      state.eyeMesh.position.z=-10;
      //state.scene.add(state.eyeMesh);
      
      // lights
      state.lightA = new DirectionalLight(0xffffff, .5);
      state.lightA.position.set(1, 2, 5);
      state.scene.add(state.lightA);
      state.lightB = new DirectionalLight(0xffffff, .5);
      state.lightB.position.set(-5, 1, -5);
      state.scene.add(state.lightB);
      state.lightC = new AmbientLight(0xffffff, 1);
      state.scene.add(state.lightC);

      const loader = new GLTFLoader();
      
      state.clock = new Clock();
      
      loader.load( '/untitled.glb', function ( gltf ) {
        
        state.head = gltf.scene;
        state.head.position.y = 0;
        state.head.position.z = 1.5;
        state.head.position.z = -1.5;
        
        state.mixer = new AnimationMixer(state.head);
	      //state.mixer.clipAction(gltf.animations[0]).play();
	      //state.mixer.clipAction(gltf.animations[0]).setDuration(10);
	      //state.mixer.clipAction(gltf.animations[1]).play();
        //state.mixer.clipAction(gltf.animations[1]).setDuration(10);
        console.log(state.head)
        state.scene.add( state.head );

      }, undefined, function ( error ) {
        console.error( error );
      } );
      loader.load( '/ropa2.glb', function ( gltf ) {
        
        state.cloth = gltf.scene;
        state.cloth.position.y = .1;
        state.cloth.position.z = -1.5;
        
        state.scene.add( state.cloth );

      }, undefined, function ( error ) {
        console.error( error );
      } );
      
    },
    RESIZE(state, { width, height }) {
      state.width = width;
      state.height = height;
      state.camera.aspect = width / height;
      state.camera.updateProjectionMatrix();
      state.renderer.setSize(width, height);
      state.controls.handleResize();
      state.renderer.render(state.scene, state.camera);
    },
    SET_CAMERA_POSITION(state, { x, y, z }) {
      if (state.camera) {
        state.camera.position.set(x, y, z);
      }
    },
    RESET_CAMERA_ROTATION(state) {
      if (state.camera) {
        state.camera.rotation.set(0, 0, 0);
        state.camera.quaternion.set(0, 0, 0, 1);
        state.camera.up.set(0, 1, 0);
        state.controls.target.set(0, 0, 0);
      }
    },
    HIDE_PYRAMIDS(state) {
      state.scene.remove(...state.pyramids);
      state.renderer.render(state.scene, state.camera);
    },
    SHOW_PYRAMIDS(state) {
      state.scene.add(...state.pyramids);
      state.renderer.render(state.scene, state.camera);
    }
  },
  actions: {
    INIT_SET_PARAMS({state, commit }, { param }) {
      state.param = param;
    },
    INIT({ state, commit }, { width, height, el }) {

      return new Promise(resolve => {
        commit("SET_VIEWPORT_SIZE", { width, height });
        commit("INITIALIZE_RENDERER", el);
        commit("INITIALIZE_CAMERA");
        commit("INITIALIZE_CONTROLS");
        commit("INITIALIZE_SCENE");

        // Initial scene rendering
        state.renderer.render(state.scene, state.camera);

        // Add an event listener that will re-render
        // the scene when the controls are changed
        state.controls.addEventListener("change", () => {
          state.renderer.render(state.scene, state.camera);
        });

        
        let scrollBkp;
        let paper = document.querySelector('html');
        let scrollHeight = paper.scrollHeight - paper.clientHeight;
        let percentScroll = paper.scrollTop/scrollHeight;
        let stateControlZ = 4.2;
        window.addEventListener("scroll", (ev) => {

          if ( state.mixer ){
            scrollHeight = paper.scrollHeight - paper.clientHeight;
            percentScroll = paper.scrollTop/scrollHeight;

   
            if(percentScroll>.75){
              //state.head.position.y = ((2.5/100)*percentScroll*200)-2.5;
            }
            
          
            state.mixer.setTime(10*percentScroll);            
            scrollBkp = paper.scrollTop;
          }
        });

        let x,y,visualAmp=2;
        let xS,yS,zS,xyS;
        window.addEventListener("mousemove", handleMouseMove);
        function handleMouseMove(t) {
          
          x = (t.clientX*visualAmp/paper.clientWidth)-visualAmp/2;
          y = (t.clientY*visualAmp/paper.clientHeight)-visualAmp/2;

          state.cloth.position.z = (-y/1.5)-1.5;
          state.head.rotation.x = (y/2)+.1;
          state.head.rotation.y = (x/2);
          state.head.children[1].rotation.x = (y/2)+.1;
          state.head.children[1].rotation.y = (x/2);
          state.head.children[0].rotation.x = (y/2)+.1;
          state.head.children[0].rotation.y = (x/2);
          state.controls.target.set(x/5,-y/5, 0);

        }

        if (navigator.permissions) {
          Promise.all([navigator.permissions.query({ name: "accelerometer" }),
                      navigator.permissions.query({ name: "magnetometer" }),
                      navigator.permissions.query({ name: "gyroscope" })])
                  .then(results => {
                      if (results.every(result => result.state === "granted")) {
                          initSensor();
                      } else {
                          console.log("Permission to use sensor was denied.");
                      }
                  }).catch(err => {
                      console.log("Integration with Permissions API is not enabled, still try to start app.");
                      initSensor();
                  });
        } else {
            console.log("No Permissions API, still try to start app.");
            initSensor();
        }
        function initSensor() {
            const options = { frequency: 60, coordinateSystem: false };
            //sensor = new AbsoluteOrientationSensor(options);
            sensor = new RelativeOrientationSensor(options);
            
            sensor.addEventListener('reading', function() {
              xS = sensor.quaternion[1]*3; //rotacion sobre z
              yS = sensor.quaternion[0]*3; //rotacion sobre X
              zS = sensor.quaternion[2]*10; //rotacion sobre Y
              xyS = sensor.quaternion[3]*10; // rotacion sobre X rara
              state.controls.target.set(xS,yS,0);
              
              
              state.head.rotation.x = (yS/3);
              state.head.rotation.y = (xS/3);
              
             })
            sensor.onerror = (event) => {
                if (event.error.name == 'NotReadableError') {
                console.log("Sensor is not available.");
                }
            }
            
            sensor.start()
        }


        resolve();
      });
    },
    ANIMATE({ state, dispatch }) {
      if(state.eyeMesh && state.param){
        state.eyeMesh.position.x = state.param.x;
        state.eyeMesh.position.y = state.param.y;
        state.eyeMesh.position.z = state.param.z;
      } 
      var time = new Date().getTime() * 0.00005; 
      state.textMesh.forEach((mesh)=>{
        mesh.position.x += 0.05;
        mesh.updateMatrix();
        if(mesh.position.x>50){
          mesh.position.x=-50;
          mesh.position.y = (Math.random() * 20) -10;
          mesh.position.z =  (Math.random() * -15) -14;
        }
      });
      
      if ( state.mixer ) state.mixer.update( state.clock.getDelta() );
      window.requestAnimationFrame(() => {
        dispatch("ANIMATE");
        state.controls.update();
        state.renderer.render(state.scene, state.camera)
      });
    
    }
  },
  modules: {
  }
})