<template>
    <div class="decoBoxes"
         v-bind:style="{
                width:boxSizeW+'%',
                height:boxSizeH+'%',
                zIndex:zIndex
            }">
        <div class="decoBox" v-for="(block, index) of decoBoxes"
            :key="index"
            v-bind:style="{
                top:block.top+'%',
                left:block.left+'%',
                zIndex:block.size,
                transitionDuration:'10s',
                marginLeft:'-'+(block.size/2)+'px'
            }">
            <img :style="{
                    maxWidth:block.size+'px',
                    maxHeight:block.size+'px',
                    boxShadow:'0 0 '+block.zIndex/2+'px '+3+'px rgba(0,0,0,0.5)'
                    
                    }"
                :src="require(`../assets/works/3d/Exteriores/web/${block.id+1}.jpg`)"/>
        </div>
    </div>
</template>

<script>

export default {
    name:'DecoBoxes',
    props: ['boxSizeH', 'boxSizeW', 'boxQuantity', 'zIndex'],
    data(){
        return{
            decoBoxes:[]
        }
    },
    beforeMount(){
        this.generateDecoBoxes();
        setTimeout(this.generateDecoBoxes,1000);
        setInterval(this.generateDecoBoxes,10000);
    },
    computed:{
        dinamicSrcImage() {
            console.log('holaa')
            return require(`../assets/works/3d/Exteriores/web/2.jpg`);
        }

    },
    methods:{
        generateDecoBoxes: function () {		
            this.decoBoxes = [];
            for (let i = 0; i < this.boxQuantity; i++) {
                this.decoBoxes.push({
                    id: i,
                    top: Math.floor(Math.random() * 100),
                    left: Math.floor(Math.random() * 100),
                    size: Math.floor(Math.random() * 300 + 150),
                    zIndex:Math.floor(Math.random() * 5 + 1)
                })
            }
        }   
    }
}
</script>

<style scoped>
.decoBox img{
    transition:all 5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition:all 5s ease-in-out;
    
}
.decoBox{
    transition:all 5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition:all 5s ease-in-out;
    
    position: absolute;
    border-radius:5px;
    background:linear-gradient(rgba(123,19,43,.8) 0%, rgba(0,0,0,0.4) 100%);
    background:linear-gradient(rgba(255,255,255,.1) 0%, rgba(0,0,0,0.2) 100%);
    /*background: white;*/
    box-shadow: 0 0 4px 0px rgba(0,0,0,0.4);
}
.decoBoxes{
    height:200px;
    width:200px;
    transform: scaleX(1);
}
</style>