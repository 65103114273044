<template>
    <div>
        <div id="slide1" class="slide">
            <div class="whiteBackground">
                <div id="logo">
                    <div id="a"><img class="svg" id="animatedSVG" type="image/svg+xml" :src="`https://diegobergua.com/img/imago_nombre_animated.svg?v=${random}`"></div>
                    <div id="b" style="display: none;"><img class="svg" type="image/svg+xml" src="@/assets/logo_color.svg"></div>
                    <div id="c" style="display: none;"><img class="svg shadowLogo" type="image/svg+xml" src="@/assets/imago_nombre_blanco.svg"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DecoBoxes from '@/components/DecoBoxes.vue'

export default {
    name: "Logo",
    components:{
        DecoBoxes
    },
    data(){
        return{
            random:0
        }
        
    },
    created: function(){
        this.random = Math.random();
    },
    mounted: function(){
        $("#slide1").css('height', (window.innerHeight)+"px");
        setTimeout(showLogo, 2000);
        function showLogo(){
            
            $("#a").fadeOut(2000);
            $("#b").fadeIn(1000, function(){
            $("#b").fadeOut(1000);
            $("#c").fadeIn(2000);
            $(".whiteBackground").css('background', 'transparent');
            $("#logo").css('transform', 'scale(0.8)');
            setTimeout(function(){
                //$("#slide1").css('height', (window.innerHeight/2)+"px");
                $("#slide1").css('height', 0+"px");
                //$("#slide1").css('width', 100+"px");
                //$("#logo").css('transition-duration', '0.65s');
                $("#logo").css('transform', 'scale(0)');
                $("#slide1").css('opacity', '0');
                var isMobile = /iPhone|iPad|Android/i.test(navigator.userAgent);
                if (!isMobile) {

                //initThree();

                }
                
            }, 2500)
            });
        }
    },
    methods: {
        getImgUrl: function () {
            return require('@/assets/imago_nombre_animated.svg?v='+this.random)
        }
    }
}
</script>

<style scoped>
svg {
   pointer-events: none;
}
.svg{position:absolute;left:0}
.shadowLogo{
  -webkit-filter: drop-shadow( -3px 3px 5px rgba(0, 0, 0, .5));
  filter: drop-shadow( -3px 3px 5px rgba(0, 0, 0, .5));
}
#slide1{
    transition: all 1s cubic-bezier(0.25, 1, 0.5, 1);
    width:100%;
    background: #7B132B;
    background: radial-gradient(circle, rgb(141 184 219) 0%, rgb(49 80 105) 100%);
    background: radial-gradient(circle, rgb(50 50 50) 0%, rgb(0 0 0) 100%);
    box-shadow: 0 0 30px 10px rgb(0 0 0 / 40%);
    position: fixed;
    top:0;
    z-index: 11;
    border-bottom: 1px solid #7a9fbe;
}
.whiteBackground{
  transition: all 2s ease-in-out;
  height:100%;
  width:100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#logo{
  transform-origin: center center;
  transition: all 2s cubic-bezier(0.25, 1, 0.5, 1);
  position: absolute;
  width: 164px;
  height: 159px;
}
</style>