<template>
    <div class="galleryBox">

        <stack :column-min-width="300" :gutter-width="0" :gutter-height="0" monitor-images-loaded>
            <stack-item class="img-container" v-for="(item, i) in works" :key="i">
                <img :src="require(`../assets/works/3d/Exteriores/web/${item}.jpg`)">
            </stack-item>
        </stack>
    </div>
</template>

<script>
import { Stack, StackItem } from 'vue-stack-grid'

export default {
    name:'DecoBoxes',
    components: { Stack, StackItem },
    props: ['boxSizeH', 'boxSizeW', 'boxQuantity', 'zIndex'],
    data(){
        return{
            decoBoxes:[],
            works:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
        }
    },
    beforeMount(){
        this.generateDecoBoxes();
        setTimeout(this.generateDecoBoxes,1000);
        setInterval(this.generateDecoBoxes,10000);
    },
    computed:{
        dinamicSrcImage() {
            console.log('holaa')
            return require(`../assets/works/3d/Exteriores/web/2.jpg`);
        }

    },
    methods:{
        generateDecoBoxes: function () {		
            this.decoBoxes = [];
            for (let i = 0; i < this.boxQuantity; i++) {
                this.decoBoxes.push({
                    id: i,
                    top: Math.floor(Math.random() * 100),
                    left: Math.floor(Math.random() * 100),
                    size: Math.floor(Math.random() * 300 + 150),
                    zIndex:Math.floor(Math.random() * 5 + 1)
                })
            }
        }   
    }
}
</script>

<style lang="scss" scoped>
.galleryBox{
    .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: center;
        overflow: hidden;
        margin: 0;
        object-fit: fill;
        cursor: pointer;
    }
    .img-container img {
        display: block;
        margin: 0;
        width: 100%;
        height: auto;
        border:1px solid black;
    }
    .img-container figcaption {
        margin: 3px 0;
        text-align: center;
    }
}

</style>