var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"decoBoxes",style:({
            width:_vm.boxSizeW+'%',
            height:_vm.boxSizeH+'%',
            zIndex:_vm.zIndex
        })},_vm._l((_vm.decoBoxes),function(block,index){return _c('div',{key:index,staticClass:"decoBox",style:({
            backgroundColor:block.color,
            width:block.size+'px',
            height:block.size+'px',
            top:block.top+'%',
            left:block.left+'%'
        })})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }