var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"decoBoxes",style:({
            width:_vm.boxSizeW+'%',
            height:_vm.boxSizeH+'%',
            zIndex:_vm.zIndex
        })},_vm._l((_vm.decoBoxes),function(block,index){return _c('div',{key:index,staticClass:"decoBox",style:({
            top:block.top+'%',
            left:block.left+'%',
            zIndex:block.size,
            transitionDuration:'10s',
            marginLeft:'-'+(block.size/2)+'px'
        })},[_c('img',{style:({
                maxWidth:block.size+'px',
                maxHeight:block.size+'px',
                boxShadow:'0 0 '+block.zIndex/2+'px '+3+'px rgba(0,0,0,0.5)'
                
                }),attrs:{"src":require(("../assets/works/3d/Exteriores/web/" + (block.id+1) + ".jpg"))}})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }