<template>
    <div style="height:100vh">
        <v-container>
            <v-row>
                <v-col>
                    <div>
                        Designer
                    </div>
                    <div>
                        I’m a Sydney-based UI/UX product designer that specialises in UI design and design systems. I've worked client-side at a range of startups, tech companies and corporates. I enjoy the challenge of solving complex customer problems and planning the user experience from the ground up, from research and discovery all the way through to the visual designs. I’ve been lucky enough to have my work featured in a bunch of books and publications around the world, and I enjoy sharing my knowledge and love for design by blogging and speaking at design events.
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

export default {
    name: "Header",
    components: {
    },
    data(){
        return{
            hue:0,
            blur:1
        }
    },
    
    mounted(){
        this.animateHue()
        let scrollBkp;
        let paper = document.querySelector('html');
        window.addEventListener("scroll", (ev) => {
            let scrollHeight = paper.scrollHeight - paper.clientHeight;
            let percentScroll = paper.scrollTop/scrollHeight;
            //this.hue+=5;
            paper.scrollTop>scrollBkp?this.blur+=.5:this.blur-=.5;
            this.blur<1?this.blur=1:this.blur;
            paper.scrollTop<10?this.blur=1:this.blur;
            scrollBkp = paper.scrollTop;
        });

    },
    methods:{
        animateHue(){
            window.requestAnimationFrame(() => {
                this.hue++;
                this.animateHue()
            });
        }
    }
}
</script>
<style scoped>
.cage{
    /*backdrop-filter: blur(2px) brightness(3) contrast(10) saturate(0);
    backdrop-filter: blur(2px) brightness(2) contrast(2) saturate(.1) hue-rotate(200deg);*/    
    /*background: #15022B;
    background: -webkit-linear-gradient(bottom right, #15022B, #4A4685);
    background: -moz-linear-gradient(bottom right, #15022B, #4A4685);
    background: linear-gradient(to top left, #15022B, #4A4685);*/
    opacity: 1;
    height: 100%;
    z-index: 10;
    position: relative;

    /*mix-blend-mode: hard-light;*/
}
.designerCage{
    /*backdrop-filter: blur(2px) brightness(3) contrast(10) saturate(0);
    backdrop-filter: blur(2px) brightness(2) contrast(2) saturate(.1) hue-rotate(200deg);*/    
    background: #15022B;
    background: -webkit-linear-gradient(bottom right, #15022B, #4A4685);
    background: -moz-linear-gradient(bottom right, #15022B, #4A4685);
    background: linear-gradient(to top left, #15022B, #4A4685);
    mix-blend-mode: color-burn;
}
</style>