<template>
    <div class="decoBoxes"
         v-bind:style="{
                width:boxSizeW+'%',
                height:boxSizeH+'%',
                zIndex:zIndex
            }">
        <div class="decoBox" v-for="(block, index) of decoBoxes"
            :key="index"
            v-bind:style="{
                backgroundColor:block.color,
                width:block.size+'px',
                height:block.size+'px',
                top:block.top+'%',
                left:block.left+'%'
            }">
        </div>
    </div>
</template>

<script>

export default {
    name:'DecoBoxes',
    props: ['boxSizeH', 'boxSizeW', 'boxQuantity', 'zIndex'],
    data(){
        return{
            decoBoxes:[]
        }
    },
    beforeMount(){
        setInterval(this.generateDecoBoxes,1000);
    },
    computed:{

    },
    methods:{
        generateDecoBoxes: function () {		
            this.decoBoxes = [];
            for (let i = 0; i < this.boxQuantity; i++) {
                this.decoBoxes.push({
                    id: i,
                    top: Math.floor(Math.random() * 100),
                    left: Math.floor(Math.random() * 100),
                    size: Math.floor(Math.random() * 70 + 20),
                    color:'rgba('+Math.floor(Math.random() * 245 + 10)+','+Math.floor(Math.random() * 245 + 10)+','+Math.floor(Math.random() * 245 + 10)+','+0.15+')'
                })
            }
        }   
    }
}
</script>

<style>
.decoBox{
    transition:all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: absolute;
    border-radius:5px;
    background:linear-gradient(rgba(123,19,43,.8) 0%, rgba(0,0,0,0.4) 100%);
    background:linear-gradient(rgba(255,255,255,.1) 0%, rgba(0,0,0,0.2) 100%);
    /*background: white;*/
    box-shadow: 0 0 4px 0px rgba(0,0,0,0.4);
}
.decoBoxes{
    height:200px;
    width:200px;
    position: absolute;
    top: 40px;
    right: 40px;
    transform: scaleX(1);
}
</style>