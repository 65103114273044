<template>
  <div class="home" id="diegobergua">
    <Logo />
    <div
      style="
        font-size: 40px;
        color: black;
        margin-top: 85vh;
        z-index: 9;
        position: relative;
      "
    >
      <span style="font-size: 100px" class="downArrow">ꜜ</span>
    </div>
    <!--
    <input
      type="number"
      v-model="param.x"
      @keyup="paramsChange()"
      style="position: absolute; top: 0; z-index: 1000000; left: 0"
    />
    <input
      type="number"
      v-model="param.y"
      @keyup="paramsChange()"
      style="position: absolute; top: 50px; z-index: 1000000; left: 0"
    />
    <input
      type="number"
      v-model="param.z"
      @keyup="paramsChange()"
      style="position: absolute; top: 100px; z-index: 1000000; left: 0"
    />
    -->
   

    <Presentation />

    <div style="height: 900px"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Logo from "@/components/Logo.vue";
import Header from "@/components/Header.vue";
import Presentation from "@/components/Presentation.vue";
import Portfolio from "@/components/Portfolio.vue";


import { mapActions } from "vuex";
import ThreeComp from '../components/ThreeComp.vue';

export default {
  name: "Home",
  components: {
    Logo,
    Header,
    Presentation,
    Portfolio,
    ThreeComp
  },
  data() {
    return {
      param: {},
    };
  },
  created() {
    this.param.x = 0;
    this.param.y = 0;
    this.param.z = 0;

  },
  methods: {
    ...mapActions(["INIT_SET_PARAMS"]),
    paramsChange: function () {
      this.INIT_SET_PARAMS({
        param: this.param,
      });
    },
  },
};
</script>

<style lang="scss">
.downArrow {
  display: block;
  animation: pointingDowm 1s ease-in-out infinite;
}
@keyframes pointingDowm {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
